import { DateTime } from 'luxon';

import SchooliesCountdown from './SchooliesCountdown';
import './App.css';

const App = () => {
  return (
  <div className="App">
    <div className="container">
      <div className="content">
        <h1>MATE IS CHUFFED!!!!!!!1</h1>
        <p><SchooliesCountdown /></p>
      </div>
    </div>
    <footer>
      <p>Copyright &copy; { DateTime.now().year } - Mate Corp Pty Ltd Etc.</p>
    </footer>
  </div>
)};

export default App;
