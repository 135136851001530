import { DateTime } from 'luxon';
import Countdown from 'react-countdown';

const InRange = () => <span>Schoolies is on now!! WOOOOO!!!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => completed
  ? <InRange />
  : <span>Schoolies is { days } days, { hours } hours, { minutes } minutes and { seconds } seconds away! too long!!!!!</span>

const SchooliesCountdown = ({ date }) => {
  if (!date) {
    const tz = 'Australia/Brisbane';
    const today = DateTime.now().setZone(tz).startOf('day');
    const start = { month: 11, day: 20 };
    const end = { month: 11, day: 30 };
    const isInRange = today >= today.set(start) && today < today.set(end);
    const years = !isInRange && today > end ? 1 : 0;
    date = today.set(start).plus({ years });
  }
  date = date instanceof DateTime ? date.toJSDate() :  new Date(date);
  return <Countdown date={ date } renderer={ renderer } />;
}

export default SchooliesCountdown;
